<template>
  <div class="col-md-12" align="center">
    <img src="/assets/img/404notfound.jpg">
  </div>
</template>
<script>
   export default {
     name: "pagenotfound"
   };
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>